@font-face {
    font-family: 'PP Pangram Sans';
    src: url(fonts/PPPangramSans-Regular.woff2);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PP Pangram Sans';
    src: url(fonts/PPPangramSans-Semibold.woff2);
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PP Eiko';
    src: url(fonts/PPEiko-Regular.woff2);
    font-weight: normal;
    font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;